<template>
	<div class="box box_mm">
		<div class="box_main">
			<div class="search_box">
				<div class="search_item">
					<span style="width: 0.6rem;">名称：</span>
					<el-input v-model="searchinput" placeholder="请输入名称" style="width: 2rem;"></el-input>
				</div>
				<div class="search_item">
					<el-button type="primary" @click="search">搜索</el-button>
					<el-button type="info" @click="reset">重置</el-button>
					<el-button v-show='!selecthe' type="success" @click="addnew()"> 新增提案 </el-button>
				</div>
			</div>
			<div class="box_cont">
				<div class="btn_list">
					<!-- <el-button type="primary" @click="goaddnew">新增</el-button> -->
				</div>
				<div style="height: 76vh;box-sizing: border-box;">
					<MyTable ref="zjlist" :tableData="tableData" :tableHeader="tableHeader" :showselect="selecthe"
						:showbm="true" :czwidth="440" @getdata="gettable">
						<template slot="btns" slot-scope="{ scope }">
							<el-button @click="look(scope.row)" type="success" size="mini">详情</el-button>
							<el-button @click="edit(scope.row)" v-if="scope.row.typemine==-999||scope.row.type==3" type="warning" size="mini">编辑</el-button>
							<el-button @click="showly(scope.row)" v-if="scope.row.type==3" type="danger" size="mini">查看驳回理由</el-button>
							<el-button v-show="scope.row.zdta==1&&scope.row.type!=1&&scope.row.type!=4&&scope.row.type!=3" @click="sqzd(scope.row)" type="primary" size="mini">申请重点提案</el-button>
							
							<el-button v-show="scope.row.type>=5" @click="showcbdw(scope.row)" type="success" size="mini">查看答复函</el-button>
							<el-button v-show="scope.row.type==6" @click="showpostyjb(scope.row)" type="primary" size="mini">提交办理情况征询表</el-button>
							<el-button v-show="scope.row.type==7" @click="chakanyjb(scope.row)" type="success" size="mini">查看办理情况征询表</el-button>
							
						</template>
					</MyTable>
				</div>
			</div>
		</div>


		<!--详情-->
		<mydetail v-if="showdetail" :id="detail_id" :showdy="true" requsturl="/api/proposa/details"
			@closedetail="showdetail=false">
			<div slot="otherbtn" style="display: flex;flex-direction: column;">
				

			</div>

		</mydetail>


		<!--承办单位列表-->
		<cbdwtc ref="mycbdwtc"></cbdwtc>


		<!--办理情况征询表-->
		<el-dialog title="提交办理情况征询表" :visible.sync="showyjb" width="40%"
			:before-close="closeislook">
			<div>
				<el-form ref="yjform" label-width="auto">
					<el-form-item label="委员对办理的具体意见和建议" required class="yjbtitle" style="display: flex;flex-direction: column;">
						<div>
							<el-radio-group v-model="sfmy">
								<el-radio label="3">满意</el-radio>
								<el-radio label="2">基本满意</el-radio>
								<el-radio label="1">不满意</el-radio>
							</el-radio-group>
							<el-input
							  type="textarea"
							  :rows="4"
							  placeholder="请输入意见和建议"
							  v-model="qhzxyjb">
							</el-input>
						</div>						
					</el-form-item>
					<el-form-item label="是否推荐为优秀承办单位" required class="yjbtitle" style="display: flex;flex-direction: column;">
						<div>
							<el-radio-group v-model="yxdwtype">
								<el-radio label="2">是</el-radio>
								<el-radio label="1">否</el-radio>
							</el-radio-group>
						</div>						
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="closeislook">取 消</el-button>
				<el-button type="primary" @click="postyjb">提 交</el-button>
			</span>
		</el-dialog>
		
		<!--查看--情况征询表-->
		<yjbck ref="myyjbck"></yjbck>
	</div>
</template>

<script>
	import mydetail from "@/components/detail_tc.vue";
	import cbdwtc from './component/cbdw_list.vue';
	import yjbck from './component/yjbck.vue';
	export default {
		components: {
			mydetail,
			cbdwtc,
			yjbck
		},
		data() {
			return {
				showdetail: false,
				detail_id: '',
				searchinput: '',
				tableHeader: [
					{
						name: 'id',
						prop: 'id',
						width: '50'
					},
					{
						name: '标题',
						prop: 'name',
						width: '300'
					},
					{
						name: '提案类别',
						prop: 'lei_name',
						width: ''
					},
					{
						name: '提交人',
						prop: 'user_name',
						width: ''
					},
					{
						name: '提交时间',
						prop: 'addtime',
						width: ''
					},
					{
						name: '状态',
						prop: 'typemine',
						width: '120',
						type: 'tags'
					},
					{
						name: '是否重点',
						prop: 'zdta',
						width: '100',
						type: 'tags_zdta'
					},
				],
				tableData: [],
				
				
				selectid:'',
				showyjb:false,
				sfmy:'',
				yxdwtype:'2',//2推荐  1正常
				qhzxyjb:'',
			}
		},
		mounted() {
			this.gettable()

			//获取承办单位数据
			this.$post({
				url: '/api/organizational/index',
				params: {
					p: 1,
					size: 9999,
					pid: 3
				}
			}).then((res) => {
				this.danwei = res.list
			})
			//获取提案类型
			this.$post({
				url: '/api/lei/index',
				params: {
					p: 1,
					size: 9999
				}
			}).then((res) => {
				this.taleix = res.list
			})

		},
		methods: {
			postyjb(){
				this.$post({
					url: '/api/proposa/yjb',
					params: {
						id:this.selectid,
						sfmy:this.sfmy,
						qhzxyjb:this.qhzxyjb,
						yxdwtype:this.yxdwtype
					}
				}).then((res) => {
					this.$message.success('提交成功')
					this.gettable()
					this.closeislook()
				})
				
			},
			chakanyjb(item){
				this.$refs.myyjbck.detail = item
				this.$refs.myyjbck.ckyjbshow = true
			},
			showpostyjb(row){
				this.selectid = row.id
				this.showyjb = true
				
			},
			closeislook(){
				this.showyjb = false
			},
			//查看承办单位
			showcbdw(item) {
				item.organizational_list.forEach((item, index) => {
					let cbdw_type = 1 //1未答复、2已答复、3已办理、4、有异议--提案 5、采纳--意见，6、不采纳--意见
					if (item.dfhtype == 1 && item.state == 2) {
						cbdw_type = 4
					} else if (item.dfhtype != 1) {
						cbdw_type = item.dfhtype==4?3:item.dfhtype
					} else if (item.dfhtype == 1 && item.sfcn == 0) {
						cbdw_type = 1
					} else if (item.dfhtype == 1 && item.sfcn == 1) {
						cbdw_type = 5
					} else if (item.dfhtype == 1 && item.sfcn == 2) {
						cbdw_type = 6
					}
					
					item.cbdw_type = cbdw_type
				})
				this.$refs.mycbdwtc.tableData = item.organizational_list
				this.$refs.mycbdwtc.showyy = true
			},
			edit(row){
				this.$router.push('/system/edit_tian_mine?id='+row.id)
			},
			
			showly(row){
				this.$confirm(row.bylaly, '不予立案理由', {
					confirmButtonText: '编辑提交',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$router.push('/system/edit_tian_mine?id=' + row.id)
				}).catch(() => {
					
				})
			},
			look(item) {
				
				this.detail_id = item.id
				this.showdetail = true
			},
			goaddnew() {
				this.$router.push('/system/add_zhengji')
			},
			addnew() {
				this.$router.push('/system/add_tian_mine')
			},
			reset() {
				this.searchinput = ''
				this.$refs.zjlist.inputpage = 1
				this.gettable()
			},
			search() {
				this.$refs.zjlist.inputpage = 1
				this.gettable()
			},
			gettable() {
				this.$post({
					url: '/api/proposa/appindex',
					params: {
						type:'',
						p: this.$refs.zjlist.inputpage,
						size: this.$refs.zjlist.pagesize
					}
				}).then((res) => {
					res.list.forEach((item,index)=>{
						item.typemine = item.status==1?-999:item.type
					})
					this.tableData = res.list
					this.$refs.zjlist.total = res.count
				})
			},
			sqzd(row) {
				this.$confirm('确认申请重点提案吗？')
					.then(_ => {
						this.$post({
							url: '/api/proposa/qtcz',
							params: {
								ids:row.id,
								zdta:2
							}
						}).then((res) => {
							this.$message.success('申请成功')
							this.gettable()
						})
					})
					.catch(_ => {});

			},
			showsetrole(row) {
				console.log(row)
				this.$refs.mymx.showmx = true
			},
			changswitch(row) {
				console.log(row)
			},
			showedit(row) {
				this.$router.push({
					path: '/system/swiper_edit',
					query: {
						id: row.id
					}
				})
			},

		},
	}
</script>

<style lang="scss" scoped>
	::v-deep .yjbtitle .el-form-item__content{
		margin-left: 0 !important;
	}
	::v-deep .yjbtitle .el-form-item__label-wrap{
		margin-left: 0 !important;
	}
	.box_main {
		background-color: #fff;
		padding: 15px 25px;
		width: 100%;
		box-sizing: border-box;
		min-height: 100%;
	}

	.search_box {
		display: flex;
		align-items: center;
		font-size: 0.16rem;

		.search_item {
			display: flex;
			align-items: center;
			margin-right: 0.8rem;

		}
	}

	.box_cont {
		margin-top: 20px;
	}

	.btn_list {
		display: flex;
		align-items: center;
	}

	.tc_title {
		font-size: 0.18rem;
		font-weight: bold;
		color: #333;
		position: relative;
		padding-left: 20px;
		margin-bottom: 20px;
	}

	.tc_title:after {
		content: '';
		position: absolute;
		height: 100%;
		width: 4px;
		left: 0px;
		background-color: rgb(26, 144, 253);
	}

	::v-deep td {
		text-align: center !important;
	}

	::v-deep .el-table th.el-table__cell>.cell {
		text-align: center !important;
	}

	::v-deep .el-table th.el-table__cell.is-leaf {
		background-color: rgb(242, 242, 242);
		color: #777;
	}

	::v-deep .el-table th.el-table__cell.is-leaf,
	.el-table td.el-table__cell {}

	::v-deep .el-table td.el-table__cell {}

	::v-deep .el-table {
		margin-top: 10px;

	}

	::v-deep .el-table__fixed {}

	::v-deep .el-table::before {
		background-color: #fff !important;
	}

	::v-deep .el-table__fixed::before {
		background-color: #fff !important;
	}

	::v-deep .el-dialog__header {
		display: flex !important;
	}

	::v-deep .el-dialog__footer span {
		display: flex !important;
		justify-content: space-around;
		width: 100%;
	}
</style>